<template>
    <div class="layout">
        <router-view></router-view>
    </div>
</template>

<script>
export default {
    name: "Layout",
    data() {
        return {};
    },
    created() {},
    methods: {}
};
</script>

<style scoped>
</style>


